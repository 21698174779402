<template>
  <div class="testimonial-area py-120" :style="backgroundStyle()">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mx-auto">
          <div class="site-heading text-center">
            <span class="site-title-tagline">Testimonials</span>
            <h2 class="site-title text-white">
              Phụ huynh và học sinh nói về <span>Azzura</span>
            </h2>
            <div class="heading-divider"></div>
          </div>
        </div>
      </div>
      <div class="testimonial-slider owl-theme">
        <swiper
          :breakpoints="breakpoints"
          :autoplay="true"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
        >
          <swiper-slide v-for="(d, k) in data.data" :key="objId(d, k)">
            <div class="testimonial-single">
              <div class="testimonial-content">
                <div class="testimonial-author-img" v-on:click="editItemImg(k)">
                  <ImgThumb :src="d.img" width="100" height="100" alt="" />
                </div>
                <div class="testimonial-author-info">
                  <h4
                    :contenteditable="edit == 'true' ? 'true' : 'false'"
                    v-on:blur="editItemName($event, k)"
                  >
                    {{ d.name }}
                  </h4>
                  <p
                    :contenteditable="edit == 'true' ? 'true' : 'false'"
                    v-on:blur="editItemTitle($event, k)"
                  >
                    {{ d.title }}
                  </p>
                </div>
              </div>
              <div class="testimonial-quote">
                <span class="testimonial-quote-icon"
                  ><i class="flaticon-quote-hand-drawn-symbol"></i
                ></span>
                <p
                  :contenteditable="edit == 'true' ? 'true' : 'false'"
                  v-on:blur="editItemMess($event, k)"
                >
                  {{ d.mess }}
                </p>
                <div class="testimonial-quote-icon">
                  <img src="/assets/img/icon/quote.svg" alt="" />
                </div>
              </div>
              <div class="testimonial-rate">
                <i :class="rateClass(d.rate, 1)"></i>
                <i :class="rateClass(d.rate, 2)"></i>
                <i :class="rateClass(d.rate, 3)"></i>
                <i :class="rateClass(d.rate, 4)"></i>
                <i :class="rateClass(d.rate, 5)"></i>
              </div>
              <div
                v-if="edit == 'true'"
                :contenteditable="edit == 'true' ? 'true' : 'false'"
                v-on:blur="editItemRate($event, k)"
              >
                {{ d.rate }}
              </div>

              <div v-if="edit == 'true'">
                <i v-on:click="removeObject(k)" class="fas fa-trash-alt"></i>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import ImgThumb from "@/components/Shared/ImgThumb.vue";
import Swal from "sweetalert2";
import linhhm from "../../linhhm.bootstrap";
export default {
  props: ["edit", "data"],
  data() {
    return {
      lhmIMG: null,
      breakpoints: {
        0: { slidesPerView: 1, spaceBetween: 30 },
        768: { slidesPerView: 2, spaceBetween: 30 },
        992: { slidesPerView: 4, spaceBetween: 30 },
      },
      swiper: null,
      sidesPerView: 4,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    ImgThumb,
  },
  mounted() {
    let t = this;
    if (t.edit) {
      t.lhmIMG = new linhhm.Image(false);
    }
    let text2 = this.$el.querySelector(".site-title");
    if (text2) {
      text2.innerHTML = this.data.text2;
    }
    console.log(t.$el);
    /* this.sidesPerView = Math.ceil(this.$el.innerWidth() / 300);
    window.addEventListener("resize", function () {
      t.sidesPerView = Math.ceil(t.$el.innerWidth() / 300);
    }); */
  },
  watch: {
    sidesPerView(val) {
      console.log(val);
    },
  },
  methods: {
    backgroundStyle() {
      return (
        "background: url(" +
        this.data.img +
        ");background-attachment: fixed;background-repeat: no-repeat;background-position: center; background-size: cover;"
      );
    },
    changeImage(src) {
      let t = this;
      t.data.img = src;
    },
    rateClass(drate, rate) {
      if (Number(rate) <= Number(drate)) {
        return "fas fa-star";
      } else if (Number(rate) <= Number(drate) + 1) {
        return "fas fa-star-half";
      } else {
        return "";
      }
    },
    objId(d, k) {
      return d.img + k;
    },
    editText1(event) {
      let t = this;
      t.data.text1 = event.target.innerText;
    },
    editText2(event) {
      let t = this;
      t.data.text2 = event.target.innerHTML;
    },
    removeObject(key) {
      let t = this;
      Swal.fire({
        title: "Xác nhận xóa",
        showCancelButton: true,
        confirmButtonText: "Xóa",
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          if (typeof t.data.data[key] != "undefined") {
            t.data.data.splice(key, 1);
          }
        }
      });
    },
    editItemName(event, key) {
      let t = this;
      t.data.data[key].name = event.target.innerText;
    },
    editItemTitle(event, key) {
      let t = this;
      t.data.data[key].title = event.target.innerText;
    },
    editItemMess(event, key) {
      let t = this;
      t.data.data[key].mess = event.target.innerText;
    },
    editItemRate(event, key) {
      let t = this;
      t.data.data[key].rate = event.target.innerText;
    },
    editItemImg(key) {
      let t = this;
      if (this.edit == "true") {
        t.lhmIMG.setCallback((src) => {
          t.data.data[key].img = src;
        });
        t.lhmIMG.openDialog();
      }
    },
    addObject() {
      let t = this;
      t.data.data.push({
        name: "Tên",
        title: "Chức danh",
        img: "/img/phu-huynh/a-5.png",
        mess: "Khách hàng nói",
        rate: 5,
      });
    },
    onSwiper(swiper) {
      console.log(swiper);
      this.swiper = swiper;
    },
  },
  setup() {
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSlideChange,
    };
  },
};
</script>
<style lang="less">
</style>