<template>
  <div class="blog-area py-120">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mx-auto">
          <div class="site-heading text-center">
            <span class="site-title-tagline">{{ data.text1 }}</span>
            <h2 class="site-title"></h2>
            <div class="heading-divider"></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-md-6 col-lg-4"
          v-for="(d, k) in data.data"
          :key="d.blog"
        >
          <input
            type="text"
            class="form-control"
            v-model="d.blog"
            v-if="edit == 'true'"
          />
          <BlogItem :id="d.blog" />

          <div v-if="edit == 'true'">
            <i v-on:click="removeObject(k)" class="fas fa-trash-alt"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import BlogItem from "@/components/Home/BlogItem.vue";
import Swal from "sweetalert2";
export default {
  props: ["edit", "data"],
  data() {
    return {};
  },
  components: { BlogItem },
  mounted() {
    let t = this;
    let text2 = this.$el.querySelector(".site-title");
    if (text2) {
      text2.innerHTML = this.data.text2;
    }
    console.log(t.$el);
    /* this.sidesPerView = Math.ceil(this.$el.innerWidth() / 300);
    window.addEventListener("resize", function () {
      t.sidesPerView = Math.ceil(t.$el.innerWidth() / 300);
    }); */
  },
  watch: {
    sidesPerView(val) {
      console.log(val);
    },
  },
  methods: {
    backgroundStyle() {
      return (
        "background: url(" +
        this.data.img +
        ");background-attachment: fixed;background-repeat: no-repeat;background-position: center; background-size: cover;"
      );
    },
    changeImage(src) {
      let t = this;
      t.data.img = src;
    },
    rateClass(drate, rate) {
      if (Number(rate) <= Number(drate)) {
        return "fas fa-star";
      } else if (Number(rate) <= Number(drate) + 1) {
        return "fas fa-star-half";
      } else {
        return "";
      }
    },
    objId(d, k) {
      return d.img + k;
    },
    editText1(event) {
      let t = this;
      t.data.text1 = event.target.innerText;
    },
    editText2(event) {
      let t = this;
      t.data.text2 = event.target.innerHTML;
    },
    removeObject(key) {
      let t = this;
      Swal.fire({
        title: "Xác nhận xóa",
        showCancelButton: true,
        confirmButtonText: "Xóa",
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          if (typeof t.data.data[key] != "undefined") {
            t.data.data.splice(key, 1);
          }
        }
      });
    },
    addObject() {
      let t = this;
      t.data.data.push({ blog: "646c70195819a2cd35a4dea9" });
    },
  },
};
</script>
<style lang="less">
</style>