<template>
  <img v-if="!isSrcset" :src="thumbSrc" />
  <img v-if="isSrcset" :srcset="thumbSrc" :sizes="sizes" />
</template>
<script>
export default {
  props: ["src", "width", "height", "sClass", "sizes"],
  mounted() {
    console.log(this.src, this.width, this.height);
  },
  computed: {
    isSrcset() {
      return !/^[0-9]+$/.test(this.width);
    },
    thumbSrc() {
      if (/^\/img\//.test(this.src)) {
        let match = /^\/img\/(.+)$/i.exec(this.src);
        let widthArr = this.width.split(",").map((i) => i.trim());
        let heightArr = this.height.split(",").map((i) => i.trim());
        if (widthArr.length == heightArr.length && this.isSrcset) {
          let srcSet = [];
          for (let i = 0; i < widthArr.length; i++) {
            srcSet.push(
              "/img-thumb/" +
                match[1] +
                "." +
                widthArr[i] +
                "." +
                heightArr[i] +
                ".webp" +
                " " +
                widthArr[i] +
                "w"
            );
          }
          return srcSet.join(", ");
        } else {
          return (
            "/img-thumb/" +
            match[1] +
            "." +
            this.width +
            "." +
            this.height +
            ".webp"
          );
        }
      }
      return this.src;
    },
  },
  methods: {
    minWidth(w) {
      return "(min-width: " + w + "px)";
    },
  },
};
</script>