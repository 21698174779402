import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from "../store/index";
let router;
function redirectToAdminLogin() {
  if (store.state.adminLogin == null) {
    if (/^\/admin\/.+/.test(window.location.pathname)) {
      store.commit("adminRedirectLink", window.location.pathname);
    }
    router.replace("/admin/login");
  }
}
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/gioi-thieu',
    name: 'AboutView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/anh',
    name: 'HinhAnh',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/HinhAnh.vue')
  },
  {
    path: '/lien-he',
    name: 'ContactView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home/ContactView.vue')
  },
  {
    path: '/shop',
    name: 'ShopView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home/ShopView.vue')
  },
  {
    path: '/khoa-hoc',
    name: 'KhoaHocView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home/KhoaHocView.vue')
  },
  {
    path: '/khoa-hoc/:alias/:id',
    name: 'KhoaHocDetailView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home/KhoaHocDetailView.vue')
  },
  {
    path: '/blogs',
    name: 'BlogsView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home/BlogView.vue')
  },
  {
    path: '/blogs/:alias/:id',
    name: 'BlogsViewWithId',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home/BlogView.vue')
  },
  {
    path: '/blog/:alias/:id',
    name: 'ArticleView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home/ArticleView.vue')
  },
  {
    path: '/san-pham/:alias/:id',
    name: 'ProductView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home/ProductView.vue')
  },
  {
    path: '/shop/:make',
    name: 'ShopViewWithMake',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home/ShopView.vue')
  },
  {
    path: '/blog/:id/:alias',
    name: 'blogView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home/BlogView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/admin',
    name: 'AdminHome',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/HomeConfig/HomeView.vue')
    }
  },
  {
    path: '/admin/about',
    name: 'AdminAbout',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/HomeConfig/AboutView.vue')
    }
  },
  {
    path: '/admin/product-option',
    name: 'AdminProductOption',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/ProductOptionView.vue')
    }
  },
  {
    path: '/admin/product-option/new',
    name: 'AdminProductNewOption',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/ProductOptionNewView.vue')
    }
  },
  {
    path: '/admin/product-option/:id',
    name: 'AdminProductEditOption',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/ProductOptionEditView.vue')
    }
  },
  {
    path: '/admin/product/:id',
    name: 'AdminProductWithId',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/ProductView.vue')
    }
  },
  {
    path: '/admin/product/new/:id',
    name: 'AdminProductNew',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/ProductNewView.vue')
    }
  },
  {
    path: '/admin/category/new/:id',
    name: 'AdminCategoryNew',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/CategoryNewView.vue')
    }
  },
  {
    path: '/admin/category/edit/:id',
    name: 'AdminCategoryEditView',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/CategoryEditView.vue')
    }
  },
  {
    path: '/admin/product/edit/:id',
    name: 'AdminProductEditView',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/ProductEditView.vue')
    }
  },
  {
    path: '/admin/article/:id',
    name: 'AdminArticleWithId',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/ArticleView.vue')
    }
  },
  {
    path: '/admin/article/new/:id',
    name: 'AdminArticleNew',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/ArticleNewView.vue')
    }
  },
  {
    path: '/admin/article/edit/:id',
    name: 'AdminArticleEdit',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/ArticleEditView.vue')
    }
  },
  {
    path: '/admin/login',
    name: 'AdminLogin',
    component: () => {
      return import('../views/Admin/LoginView.vue')
    }
  },
  {
    path: '/admin/article-group/new/:id',
    name: 'AdminArticleGroupNew',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/ArticleGroupNewView.vue')
    }
  },
  {
    path: '/admin/article-group/edit/:id',
    name: 'AdminArticleGroupEditView',
    component: () => {
      redirectToAdminLogin();
      return import('../views/Admin/ArticleGroupEditView.vue')
    }
  },
]

router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
