<template>
  <div class="main-navigation">
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <router-link class="navbar-brand" to="/">
          <img src="/assets/img/logo/logo.png" alt="logo" />
        </router-link>
        <div class="mobile-menu-right">
          <div class="mobile-menu-list">
            <a href="#" class="mobile-menu-link search-box-outer"><i class="far fa-search"></i></a>
          </div>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav"
            aria-expanded="false" aria-label="Toggle navigation" v-on:click="showMenu = !showMenu">
            <span class="navbar-toggler-icon"><i class="far fa-stream"></i></span>
          </button>
        </div>
        <div v-if="navData" class="collapse navbar-collapse" id="main_nav" v-bind:class="{ show: showMenu }">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link class="nav-link" to="/gioi-thieu">Về Azzura</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/khoa-hoc">Khóa học</router-link>
            </li>
            <!--<li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Pages</a>
                        <ul class="dropdown-menu fade-down">
                            <li><a class="dropdown-item" href="about.html">About Us</a></li>
                            <li><a class="dropdown-item" href="team.html">Our Team</a></li>
                            <li><a class="dropdown-item" href="pricing.html">Pricing Plan</a></li>
                            <li><a class="dropdown-item" href="booking.html">Online Booking</a></li>
                            <li><a class="dropdown-item" href="faq.html">Faq</a></li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle" href="#">Our Shop</a>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="shop.html">Shop</a></li>
                                    <li><a class="dropdown-item" href="shop-cart.html">Shop Cart</a></li>
                                    <li><a class="dropdown-item" href="shop-checkout.html">Shop Checkout</a>
                                    <li><a class="dropdown-item" href="shop-single.html">Shop Single</a>
                                    </li>
                                </ul>
                            </li>
                            <li><a class="dropdown-item" href="testimonial.html">Testimonials</a></li>
                            <li class="dropdown-submenu">
                                <a class="dropdown-item dropdown-toggle" href="#">Account</a>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="login.html">Login</a></li>
                                    <li><a class="dropdown-item" href="register.html">Register</a></li>
                                    <li><a class="dropdown-item" href="forgot-password.html">Forgot Password</a>
                                    </li>
                                </ul>
                            </li>
                            <li><a class="dropdown-item" href="404.html">404 Error</a></li>
                            <li><a class="dropdown-item" href="coming-soon.html">Coming Soon</a></li>
                            <li><a class="dropdown-item" href="terms.html">Terms Of Service</a></li>
                            <li><a class="dropdown-item" href="privacy.html">Privacy Policy</a></li>
                        </ul>
                    </li>-->

            <!-- <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                data-bs-toggle="dropdown"
                >Thư viện ảnh</a
              >
              <ul class="dropdown-menu fade-down">
                <li>
                  <a class="dropdown-item" href="portfolio.html"
                    >Portfolio One</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="portfolio-2.html"
                    >Portfolio Two</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="portfolio-single.html"
                    >Portfolio Single</a
                  >
                </li>
              </ul>
            </li> -->
            <li class="nav-item dropdown">
              <router-link class="nav-link dropdown-toggle" to="/blogs" data-bs-toggle="dropdown">Blog</router-link>
              <ul class="dropdown-menu fade-down">
                <li v-for="m in articleGroup" :key="m._id">
                  <router-link class="dropdown-item" :to="navBlogLink(m)">{{
                    m.name
                  }}</router-link>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/lien-he">Liên hệ</router-link>
            </li>
          </ul>
          <div class="header-nav-right">
            <div class="header-nav-search">
              <a href="#" class="header-nav-link search-box-outer"><i class="far fa-search"></i></a>
            </div>
            <div class="header-phone d-none">
              <div class="header-phone-icon">
                <img src="/assets/img/icon/phone.svg" alt="" />
              </div>
              <div class="header-phone-content">
                <span>Hotline:</span>
                <h5 class="header-phone-number">
                  <a href="tel:+2123654789">0944 636 889</a>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showMenu: false,
    };
  },
  mounted() { },
  computed: {
    navData() {
      return this.$store.getters.navData;
    },
    categories() {
      if (this.navData) {
        return this.navData.categories;
      }
      return null;
    },
    articleGroup() {
      if (this.navData) {
        return this.navData.articleGroup;
      }
      return null;
    },
  },
  methods: {
    navBlogLink(b) {
      return "/blogs/" + b.alias + "/" + b._id;
    },
    categoryLink(name) {
      return "/shop/" + encodeURIComponent(name);
    },
  },
};
</script>
<style>
.xd-sp {
  display: none;
}

@media (min-width: 992px) {

  .xd-sp {
    display: block;
  }

  .x-1000 {
    display: none;
  }

  .x-1050 {
    display: none;
  }

  .x-1100 {
    display: none;
  }

  .x-1150 {
    display: none;
  }

  .x-1200 {
    display: none;
  }

  .x-1250 {
    display: none;
  }

  .x-1300 {
    display: none;
  }

  .x-1350 {
    display: none;
  }

  .x-1400 {
    display: none;
  }

  .x-1450 {
    display: none;
  }

  .x-1500 {
    display: none;
  }

  .x-1550 {
    display: none;
  }

  .x-1600 {
    display: none;
  }
}

@media (min-width: 1000px) {
  .x-1000 {
    display: block;
  }

  .xd-1000 {
    display: none;
  }
}

@media (min-width: 1050px) {
  .x-1050 {
    display: block;
  }

  .xd-1050 {
    display: none;
  }
}

@media (min-width: 1100px) {
  .x-1100 {
    display: block;
  }

  .xd-1100 {
    display: none;
  }
}

@media (min-width: 1150px) {
  .x-1150 {
    display: block;
  }

  .xd-1150 {
    display: none;
  }
}

@media (min-width: 1200px) {
  .x-1200 {
    display: block;
  }

  .xd-1200 {
    display: none;
  }
}

@media (min-width: 1250px) {
  .x-1250 {
    display: block;
  }

  .xd-1250 {
    display: none;
  }
}

@media (min-width: 1300px) {
  .x-1300 {
    display: block;
  }

  .xd-1300 {
    display: none;
  }
}

@media (min-width: 1350px) {
  .x-1350 {
    display: block;
  }

  .xd-1350 {
    display: none;
  }
}

@media (min-width: 1400px) {
  .x-1400 {
    display: block;
  }

  .xd-1400 {
    display: none;
  }
}

@media (min-width: 1450px) {
  .x-1450 {
    display: block;
  }

  .xd-1450 {
    display: none;
  }
}

@media (min-width: 1500px) {
  .x-1500 {
    display: block;
  }

  .xd-1500 {
    display: none;
  }
}

@media (min-width: 1550px) {
  .x-1550 {
    display: block;
  }

  .xd-1550 {
    display: none;
  }
}


@media (min-width: 1600px) {
  .xd-sp {
    display: none;

  }

  .x-1600 {
    display: block;
  }

  .xd-1600 {
    display: none;
  }
}

@media (max-width: 991px) {
  #main_nav {
    position: fixed;
    top: 74px;
    right: 0;
    width: 260px;
    padding: 20px 0;
    max-height: 400px;

    .navbar-nav {
      .nav-item {
        padding-right: 20px;
        text-align: right;
      }
    }
  }

}
</style>