<template>
    <div class="team-area pb-120">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 mx-auto">
                    <div class="site-heading text-center">
                        <span class="site-title-tagline" :contenteditable="edit == 'true' ? 'true' : 'false'"
                            v-on:blur="editText1($event)" id="text-1"></span>
                        <h2 class="site-title" :contenteditable="edit == 'true' ? 'true' : 'false'"
                            v-on:blur="editText2($event)" id="text-2"></h2>
                        <div class="heading-divider"></div>
                    </div>
                </div>
            </div>
            <div class="row pb-3" v-if="edit == 'true'">
                <div class="col-12 col-md-6">
                    <h4>ID nhóm sản phẩm</h4>
                    <div class="d-flex pt-2" style="justify-content: center;">
                        <div style="border: 1px solid black; border-radius: 10px; width: 100%; height: 32.8px; text-align: left; padding: 0 10px;"
                            :contenteditable="edit == 'true' ? 'true' : 'false'" v-on:blur="editGroupId($event)">
                            {{ data.groupId }}</div>
                    </div>

                </div>
                <div class="col-12 col-md-6">
                    <h4>Số lượng sản phẩm</h4>
                    <div class="d-flex pt-2" style="justify-content: center;">
                        <input style="border: 1px solid black; border-radius: 10px; width: 100%; padding: 0 10px;"
                            type="number" class="input-control" v-model="limit">
                    </div>

                </div>

            </div>
            <div class="row mt-5">
                <div class="col-md-6 col-lg-3" v-for="(d, k) in products" :key="k">
                    <ProductItem :id="d._id" :lastUpdate="d.lastUpdate" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Swal from "sweetalert2";
import ProductItem from "@/components/Shared/ProductItem.vue";
import linhhm from "../../linhhm.bootstrap";
const axios = require("axios").default;
export default {
    data() {
        return {
            lhmIMG: null,
            groupId: null,
            limit: 4,
            products: []
        };
    },
    props: ["edit", "data"],
    components: { ProductItem },
    mounted() {
        if (this.edit) {
            this.lhmIMG = new linhhm.Image(false);
        }
        let text2 = this.$el.querySelector("#text-2");
        if (text2) {
            text2.innerHTML = this.data.text2;
        }
        let text1 = this.$el.querySelector("#text-1");
        if (text1) {
            text1.innerHTML = this.data.text1;
        }
        if (this.data) {
            if (this.data.groupId) {
                this.groupId = this.data.groupId;
                this.getProducts();

            }
            if (this.data.limit) {
                this.limit = this.data.limit;
            }
        }

    },
    methods: {
        editItemImg(key) {
            let t = this;
            if (this.edit == "true") {
                t.lhmIMG.setCallback((src) => {
                    t.data.data[key].img = src;
                });
                t.lhmIMG.openDialog();
            }
        },
        editItemName(event, key) {
            let t = this;
            t.data.data[key].name = event.target.innerText;
        },
        editItemTitle(event, key) {
            let t = this;
            t.data.data[key].title = event.target.innerText;
        },
        editText1(event) {
            let t = this;
            t.data.text1 = event.target.innerText;
        },
        editGroupId(event) {
            let t = this;
            t.data.groupId = event.target.innerText;
            t.groupId = t.data.groupId;
            t.getProducts();
        },
        async getProducts() {
            let url = "/aapi/products?parent=" + encodeURIComponent(this.groupId) + "&type=2";
            try {
                let res = await axios.get(url);
                this.products = res.data;
            } catch {
                console;
            }
        },
        editText2(event) {
            let t = this;
            t.data.text2 = event.target.innerHTML;
        },
        addObject() {
            let t = this;
            t.data.data.push({
                img: "/assets/img/team/01.jpg",
                name: "Nguyễn Bảo Ngọc",
                title: "Quản lý",
            });
        },

        removeObject(key) {
            let t = this;
            Swal.fire({
                title: "Xác nhận xóa",
                showCancelButton: true,
                confirmButtonText: "Xóa",
            }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    if (typeof t.data.data[key] != "undefined") {
                        t.data.data.splice(key, 1);
                    }
                }
            });
        },
    },
    computed: {
    },
    watch: {
        limit(val) {
            let t = this;
            if (this.data && typeof this.data == "object") {
                t.data.limit = val;
            }
        }
    }
};
</script>