<template>
  <div class="counter-area pt-70 pb-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-sm-6" v-for="(d, k) in data" :key="k">
          <div class="counter-box">
            <div class="icon"><i :class="d.icon"></i></div>
            <div>
              <span
                class="counter"
                data-count="+"
                :data-to="d.num"
                data-speed="3000"
                :contenteditable="edit == 'true' ? 'true' : 'false'"
                v-on:blur="editNum($event, k)"
                >{{ d.num }}</span
              >
              <h6
                class="title"
                :contenteditable="edit == 'true' ? 'true' : 'false'"
                v-on:blur="editText1($event, k)"
              >
                {{ d.text1 }}
              </h6>
              <div
                v-if="edit == 'true'"
                :contenteditable="edit == 'true' ? 'true' : 'false'"
                v-on:blur="editIcon($event, k)"
              >
                {{ d.icon }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["edit", "data"],
  methods: {
    editText1(event, key) {
      let t = this;
      t.data[key].text1 = event.target.innerText;
    },
    editNum(event, key) {
      let t = this;
      t.data[key].num = event.target.innerText;
    },
    editIcon(event, key) {
      let t = this;
      t.data[key].icon = event.target.innerText;
    },
    addObject() {
      let t = this;
      t.data.push({
        icon: "icon-car-wash",
        num: 1200,
        text1: "+ Xe được phục vụ",
      });
    },
  },
};
</script>
<style lang="less">
.feature-item {
  height: 100%;
}
</style>