<template>
    <div class="shop-item" v-if="data">
        <div class="shop-item-img">
            <span class="shop-item-sale">Sale</span>
            <router-link :to="productLink">
                <ImgThumb :src="data.avatar" sizes="(min-width: 768px) 200px, (min-width: 992px) 250px, 300px"
                    width="350,200,250" height="200,100,130" />
            </router-link>
        </div>
        <div class="shop-item-info">
            <div>
                <router-link :to="productLink">
                    <h4 class="shop-item-title">{{ data.name }}</h4>
                </router-link>
            </div>
            <div>{{ data.make }} {{ data.model }}</div>
            <div>{{ version(data.year) }}</div>
            <div class="shop-item-price">
                {{ numberFormat(data.price) }}
            </div>
        </div>
    </div>
</template>
<style lang="less">
.product-admin-item {
    height: 220px;
    width: 150px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    border-radius: 5px;
    background: url("../../assets/images/icon-san-pham.png") bottom right no-repeat;
    background-size: 210px 210px;

    >a {
        width: 100%;
    }

    .image-wrap-1 {
        width: 150px;
        height: 150px;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
        border-radius: 5px;
        overflow: hidden;

        .image-wrap-2 {
            width: 150px;
            height: 150px;

            img {
                max-width: 150px;
                max-height: 150px;
            }
        }
    }

    .title {
        margin-top: 5px;
        font-size: 14px;
        line-height: 25px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 5px;
        font-weight: bold;
    }

    .price {
        font-size: 12px;
        padding: 0 5px;
    }
}
</style>
<script>
import ImgThumb from "@/components/Shared/ImgThumb.vue";
export default {
    props: ["id", "lastUpdate"],
    computed: {
        data() {
            return this.$store.getters.productAdmin(this.id, this.lastUpdate);
        },
        productLink() {
            if (!this.data) {
                return "/";
            } else {
                return "/san-pham/" + this.data.alias + "/" + this.data._id;
            }
        },
    },
    methods: {
        numberFormat(num) {
            return new Intl.NumberFormat("vi-VN", {
                style: "currency",
                currency: "VND",
            }).format(num);
        },
        version(year, pre = "") {
            let result = [];
            for (let i = 0; i < year.length; i++) {
                let text = year[i].year;
                let vers = [];
                for (let ver in year[i].versions) {
                    vers.push(year[i].versions[ver].v);
                }
                if (vers.length > 0) {
                    result.push(vers.map((i) => pre + i + " " + text).join(", "));
                } else {
                    result.push(text);
                }
            }
            return result.length > 0 ? "(" + result.join(", ") + ")" : "";
        },
        anhSanPham(id) {
            return "" + id;
            /*let product = this.$store.getter.products(id);
            if(product) {
                return product.
            }*/
        },
        giaSanPham(id) {
            return "" + id;
            /*let product = this.$store.getter.products(id);
            if(product) {
                return product.
            }*/
        },
        tenSanPham(id) {
            return "" + id;
            /*let product = this.$store.getter.products(id);
            if(product) {
                return product.
            }*/
        },
        backgroundStyle() {
            return (
                "background: url('" +
                this.data.avatar +
                "') center center no-repeat; background-size: contain;"
            );
        }
    },
    components: {
        ImgThumb
    }
};
</script>