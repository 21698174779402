<template>
  <div class="about-area py-120 mb-50" v-bind:class="{ 'py-120': edit != 'true', 'py-3': edit == 'true' }">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 d-flex align-items-center justify-content-center">
          <div class="about-left">
            <div class="about-img">
              <ImgThumb sClass="about-img-1" :src="data.img1" sizes="(min-width: 768px) 550px, 300px" width="300, 550"
                height="300, 550" />
              <!-- <img v-if="edit" class="about-img-1" :src="data.img1" alt="" /> -->
            </div>
            <div class="about-shape">
              <img :src="data.img2" alt="" />
            </div>
            <div class="about-experience">
              <i :class="data.icon1"></i>
              <b v-html="data.text1"></b>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="about-right">
            <div class="site-heading mb-3">
              <span class="site-title-tagline" :contenteditable="edit == 'true' ? 'true' : 'false'"
                v-on:blur="editText2($event)">
                {{ data.text2 }}
              </span>
              <h2 class="site-title" id="uqg-text3" :contenteditable="edit == 'true' ? 'true' : 'false'"
                v-on:blur="editText3($event)"></h2>
            </div>
            <p class="about-text" :contenteditable="edit == 'true' ? 'true' : 'false'" v-on:blur="editText4($event)">
              {{ data.text4 }}
            </p>
            <div class="about-list-wrapper">
              <ul class="about-list list-unstyled">
                <li :contenteditable="edit == 'true' ? 'true' : 'false'" v-on:blur="editList1($event)">
                  {{ data.list1 }}
                </li>
                <li :contenteditable="edit == 'true' ? 'true' : 'false'" v-on:blur="editList2($event)">
                  {{ data.list2 }}
                </li>
                <li :contenteditable="edit == 'true' ? 'true' : 'false'" v-on:blur="editList3($event)">
                  {{ data.list3 }}
                </li>
              </ul>
            </div>
            <a v-if="edit != 'true'" :href="data.button.link" class="theme-btn mt-4">{{ data.button.text }} <i
                class="far fa-arrow-right"></i></a>
            <span style="padding: 10px; border: 1px solid black" v-if="edit == 'true'"
              :contenteditable="edit == 'true' ? 'true' : 'false'" v-on:blur="editButtonText($event)">{{ data.button.text
              }}</span>

            <span style="padding: 10px; border: 1px solid black" v-if="edit == 'true'"
              :contenteditable="edit == 'true' ? 'true' : 'false'" v-on:blur="editButtonLink($event)">{{ data.button.link
              }}</span>
            <div style="padding: 10px; border: 1px solid black; margin: 10px 0" v-if="edit == 'true'"
              :contenteditable="edit == 'true' ? 'true' : 'false'" v-on:blur="editText1($event)"
              id="about-us-text1-html"></div>
            <div style="padding: 10px; border: 1px solid black" v-if="edit == 'true'"
              :contenteditable="edit == 'true' ? 'true' : 'false'" v-on:blur="editIcon($event)">
              {{ data.icon1 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImgThumb from "@/components/Shared/ImgThumb.vue";
export default {
  props: ["edit", "data"],
  mounted() {
    let text1 = this.$el.querySelector("#about-us-text1-html");
    if (text1) {
      text1.innerHTML = this.data.text1;
    }
    this.$el.querySelector("#uqg-text3").innerHTML = this.data.text3;
  },
  methods: {
    editText1() {
      let t = this;

      let text1 = this.$el.querySelector("#about-us-text1-html");
      if (text1) {
        t.data.text1 = text1.innerHTML;
      }
    },
    editText2(event) {
      let t = this;
      t.data.text2 = event.target.innerText;
    },
    editText3(event) {
      let t = this;
      t.data.text3 = event.target.innerHTML;
    },
    editText4(event) {
      let t = this;
      t.data.text4 = event.target.innerText;
    },
    editList1(event) {
      let t = this;
      t.data.list1 = event.target.innerText;
    },
    editList2(event) {
      let t = this;
      t.data.list2 = event.target.innerText;
    },
    editList3(event) {
      let t = this;
      t.data.list3 = event.target.innerText;
    },
    editButtonText(event) {
      let t = this;
      t.data.button.text = event.target.innerText;
    },
    editButtonLink(event) {
      let t = this;
      t.data.button.link = event.target.innerText;
    },
    editIcon(event) {
      let t = this;
      t.data.icon1 = event.target.innerText;
    },
    changeImage(src) {
      let t = this;
      t.data.img1 = src;
    },
    changeImage2(src) {
      let t = this;
      t.data.img2 = src;
    },
  },
  components: {
    ImgThumb,
  },
};
</script>
<style lang="less">
.feature-item {
  height: 100%;
}
</style>