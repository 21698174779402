import { createStore } from 'vuex'
const axios = require("axios").default;
const store = createStore({
  state: {
    adminRedirectLink: null,
    adminLogin: null,
    productsAdmin: [],
    categoriesAdmin: [],
    loadingProductAdmin: [],
    loadingProductAdminError: {},
    loadingCategoryAdmin: [],
    loadingCategoryAdminError: {},
    articlesAdmin: [],
    articleGroupAdmin: [],
    loadingArticleAdmin: [],
    loadingArticleAdminError: {},
    loadingArticleGroupAdmin: [],
    loadingArticleGroupAdminError: {},
    loadingProduct: false,
    loadingKhoaHoc: false,
    products: null,
    khoaHoc: null,
    loadingBlog: false,
    blogs: null,
    navData: null,
    loadingNav: false
  },
  getters: {
    adminRedirectLink(state) {
      return state.adminRedirectLink
    },

    navData(state) {
      if (state.navData == null) {
        if (!state.loadingNav) {
          store.commit("loadNav");
        }
        return null;
      } else {
        return state.navData;
      }
    }, blogs(state) {
      if (state.blogs == null) {
        if (!state.loadingBlog) {
          store.commit("loadBlog");
        }
        return [];
      } else {
        return state.blogs;
      }
    },
    blog(state) {
      if (state.blogs == null) {
        if (!state.loadingBlog) {
          store.commit("loadBlog");
        }
        return function () {
          return null;
        };
      } else {
        return function (id) {
          return state.blogs.find(i => i._id == id);
        };
      }
    },
    khoaHoc(state) {
      if (state.khoaHoc == null) {
        if (!state.loadingKhoaHoc) {
          store.commit("loadKhoaHoc");
        }
        return [];
      } else {
        return state.khoaHoc;
      }
    },
    product(state) {
      if (state.khoaHoc == null) {
        if (!state.loadingKhoaHoc) {
          store.commit("loadKhoaHoc");
        }
        return function () {
          return null;
        };
      } else {
        return function (id) {
          return state.khoaHoc.find(i => i._id == id);
        };
      }
    },
    productAdmin(state) {
      return function (productId, lastUpdate) {
        if (state.loadingProductAdmin.indexOf(productId) != -1 ||
          (typeof state.loadingProductAdminError[productId] == 'number' && state.loadingProductAdminError[productId] + 5000 > Date.now())) {
          return null;
        } else {
          let product = state.productsAdmin.find(i => i._id.toLowerCase() == productId.toLowerCase());
          if (product) {
            if (typeof lastUpdate != "undefined") {
              if (product.lastUpdate == lastUpdate) {
                return product;
              } else {
                state.productsAdmin.splice(state.productsAdmin.indexOf(product), 1);
              }
            } else {
              return product;
            }
          }
          store.commit("loadProductAdmin", productId);
          return null;
        }
      }
    },
    categoryAdmin(state) {
      return function (categoryId, lastUpdate) {
        if (state.loadingCategoryAdmin.indexOf(categoryId) != -1 ||
          (typeof state.loadingCategoryAdminError[categoryId] == 'number' && state.loadingCategoryAdminError[categoryId] + 5000 > Date.now())) {
          return null;
        } else {
          let category = state.categoriesAdmin.find(i => i && i._id && i._id.toLowerCase() == categoryId.toLowerCase());
          if (category) {
            if (typeof lastUpdate != "undefined") {
              if (category.lastUpdate == lastUpdate) {
                return category;
              } else {
                state.categoriesAdmin.splice(state.categoriesAdmin.indexOf(category), 1);
              }
            } else {
              return category;
            }
          }
          store.commit("loadCategoryAdmin", categoryId);
          return null;
        }
      }
    },
    categoryAdmin2(state) {
      return function (categoryId) {
        categoryId = categoryId.trim();
        if (state.loadingCategoryAdmin.indexOf(categoryId) != -1 ||
          (typeof state.loadingCategoryAdminError[categoryId] == 'number' && state.loadingCategoryAdminError[categoryId] + 5000 > Date.now())) {
          return null;
        } else {
          let category = state.categoriesAdmin.find(i => i._id.toLowerCase() == categoryId.toLowerCase());
          if (category) {
            return state.categoriesAdmin.splice(state.categoriesAdmin.indexOf(category), 1);
          } else {
            store.commit("loadCategoryAdmin", categoryId);
          }
          return null;
        }
      }
    },

    articleAdmin(state) {
      return function (articleId, lastUpdate) {
        if (state.loadingArticleAdmin.indexOf(articleId) != -1 ||
          (typeof state.loadingArticleAdminError[articleId] == 'number' && state.loadingArticleAdminError[articleId] + 5000 > Date.now())) {
          return null;
        } else {
          let article = state.articlesAdmin.find(i => i._id == articleId);
          if (article) {
            if (typeof lastUpdate != "undefined") {
              if (article.lastUpdate == lastUpdate) {
                return article;
              } else {
                state.articlesAdmin.splice(state.articlesAdmin.indexOf(article), 1);
              }
            } else {
              return article;
            }
          }
          store.commit("loadArticleAdmin", articleId);
          return null;
        }
      }
    },
    articleGroupAdmin(state) {
      return function (categoryId, lastUpdate) {
        if (state.loadingArticleGroupAdmin.indexOf(categoryId) != -1 ||
          (typeof state.loadingArticleGroupAdminError[categoryId] == 'number' && state.loadingArticleGroupAdminError[categoryId] + 5000 > Date.now())) {
          return null;
        } else {
          let category = state.articleGroupAdmin.find(i => i._id == categoryId);
          if (category) {
            if (typeof lastUpdate != "undefined") {
              if (category.lastUpdate == lastUpdate) {
                return category;
              } else {
                state.articleGroupAdmin.splice(state.articleGroupAdmin.indexOf(category), 1);
              }
            } else {
              return category;
            }
          }
          store.commit("loadArticleGroupAdmin", categoryId);
          return null;
        }
      }
    }
  },
  mutations: {
    adminRedirectLink(state, link) {
      state.adminRedirectLink = link;
    },
    async loadProduct(state) {
      try {
        let res = await axios.get("/api/products");
        state.products = res.data;
      } finally {
        state.loadingProduct = false;
      }
    },
    async loadKhoaHoc(state) {
      try {
        let res = await axios.get("/api/khoa-hoc");
        state.khoaHoc = res.data;
      } finally {
        state.loadingKhoaHoc = false;
      }
    },
    async loadNav(state) {

      try {
        let res = await axios.get("/api/nav");
        state.navData = res.data;
      } finally {
        state.loadingNav = false;
      }
    },
    async loadBlog(state) {
      try {
        let res = await axios.get("/api/blogs");
        state.blogs = res.data;
      } finally {
        state.loadingBlog = false;
      }
    },
    async loadProductAdmin(state, productId) {
      if (state.loadingProductAdmin.indexOf(productId) == -1) {
        state.loadingProductAdmin.push(productId);
        try {
          let res = await axios.get("/aapi/product/" + encodeURIComponent(productId));
          state.productsAdmin.push(res.data);
        } catch {
          state.loadingProductAdminError[productId] = Date.now();
        } finally {
          state.loadingProductAdmin.splice(state.loadingProductAdmin.indexOf(productId), 1);
        }
      }
    },
    async loadCategoryAdmin(state, categoryId) {

      if (categoryId != 0) {

        if (state.loadingCategoryAdmin.indexOf(categoryId) == -1) {
          state.loadingCategoryAdmin.push(categoryId);
          try {
            let res = await axios.get("/aapi/category/" + encodeURIComponent(categoryId));
            state.categoriesAdmin.push(res.data);
          } catch {
            state.loadingCategoryAdminError[categoryId] = Date.now();
          } finally {
            state.loadingCategoryAdmin.splice(state.loadingCategoryAdmin.indexOf(categoryId), 1);
          }
        }
      }
    },
    login(state, data) {
      state.adminLogin = data;
    },

    async loadArticleAdmin(state, articleId) {
      if (state.loadingArticleAdmin.indexOf(articleId) == -1) {
        state.loadingArticleAdmin.push(articleId);
        try {
          let res = await axios.get("/aapi/article/" + encodeURIComponent(articleId));
          state.articlesAdmin.push(res.data);
        } catch {
          state.loadingArticleAdminError[articleId] = Date.now();
        } finally {
          state.loadingArticleAdmin.splice(state.loadingArticleAdmin.indexOf(articleId), 1);
        }
      }
    },
    async loadArticleGroupAdmin(state, articleGroupId) {
      if (articleGroupId != 0) {
        if (state.loadingArticleGroupAdmin.indexOf(articleGroupId) == -1) {
          state.loadingArticleGroupAdmin.push(articleGroupId);
          try {
            let res = await axios.get("/aapi/article-group/" + encodeURIComponent(articleGroupId));
            state.articleGroupAdmin.push(res.data);
          } catch {
            state.loadingArticleGroupAdminError[articleGroupId] = Date.now();
          } finally {
            state.loadingArticleGroupAdmin.splice(state.loadingArticleGroupAdmin.indexOf(articleGroupId), 1);
          }
        }

      }
    },
  },
  actions: {
  },
  modules: {
  }
});
export default store;
