<template>
  <div class="faq-area py-120">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="faq-right">
            <div class="site-heading mb-3">
              <span
                class="site-title-tagline"
                :contenteditable="edit == 'true' ? 'true' : 'false'"
                v-on:blur="editText1($event, k)"
              >
                {{ data.text1 }}
              </span>
              <h2
                class="site-title my-3"
                :contenteditable="edit == 'true' ? 'true' : 'false'"
                v-on:blur="editText2($event, k)"
              ></h2>
            </div>
            <p
              class="about-text"
              :contenteditable="edit == 'true' ? 'true' : 'false'"
              v-on:blur="editText3($event, k)"
            >
              {{ data.text3 }}
            </p>
            <div class="faq-img mt-3" v-on:click="editImg()">
              <ImgThumb v-if="!edit" width="600" height="300" :src="data.img" />
              <img v-if="edit" :src="data.img" />
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item" v-for="(d, k) in data.data" :key="k">
              <h2
                class="accordion-header"
                id="headingOne"
                v-on:click="
                  if (activeId != k) {
                    activeId = k;
                  } else {
                    activeId = -1;
                  }
                "
              >
                <button
                  class="accordion-button"
                  v-bind:class="{ collapsed: k != activeId }"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <span><i class="far fa-question"></i></span>
                  <span
                    style="width: 100%"
                    :contenteditable="edit == 'true' ? 'true' : 'false'"
                    v-on:blur="editItemQuestion($event, k)"
                  >
                    {{ d.q }}
                  </span>
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse"
                v-bind:class="{ show: k == activeId }"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div
                  class="accordion-body"
                  :contenteditable="edit == 'true' ? 'true' : 'false'"
                  v-on:blur="editItemAnswer($event, k)"
                >
                  {{ d.a }}
                </div>
                <div v-if="edit == 'true'">
                  <i v-on:click="removeObject(k)" class="fas fa-trash-alt"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import linhhm from "../../linhhm.bootstrap";
import ImgThumb from "@/components/Shared/ImgThumb.vue";
export default {
  props: ["edit", "data"],
  data() {
    return {
      activeId: -1,
      lhmIMG: null,
    };
  },
  components: { ImgThumb },
  mounted() {
    if (this.edit) {
      this.lhmIMG = new linhhm.Image(false);
    }
    let text2 = this.$el.querySelector(".site-title");
    if (text2) {
      text2.innerHTML = this.data.text2;
    }
  },
  methods: {
    editImg() {
      let t = this;
      if (this.edit == "true") {
        t.lhmIMG.setCallback((src) => {
          t.data.img = src;
        });
        t.lhmIMG.openDialog();
      }
    },
    editItemQuestion(event, key) {
      let t = this;
      t.data.data[key].q = event.target.innerText;
    },
    editItemAnswer(event, key) {
      let t = this;
      t.data.data[key].a = event.target.innerText;
    },
    editText1(event) {
      let t = this;
      t.data.text1 = event.target.innerText;
    },
    editText2(event) {
      let t = this;
      t.data.text2 = event.target.innerText;
    },
    editText3(event) {
      let t = this;
      t.data.text3 = event.target.innerText;
    },
    addObject() {
      let t = this;
      t.data.data.push({
        q: "Câu hỏi",
        a: "Câu trả lời",
      });
    },

    removeObject(key) {
      let t = this;
      Swal.fire({
        title: "Xác nhận xóa",
        showCancelButton: true,
        confirmButtonText: "Xóa",
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          if (typeof t.data.data[key] != "undefined") {
            t.data.data.splice(key, 1);
          }
        }
      });
    },
  },
};
</script>